import { makeStyles } from "@material-ui/core";
import React from "react";

import SectionVideo from "../SectionVideo";
import SectionSteps from "../SectionSteps";
import SectionPartner from "../SectionPartner";
import SectionQualitaet from "../SectionQualitaet";
import SectionHandwerk from "../SectionHandwerk";
import SectionService from "../SectionService";
import SectionInnovation from "../SectionInnovation";
import SectionUeberUns from "../SectionUeberUns";
import PopupHint from "../PopupHint";

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: "200vh"
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <SectionVideo />
        <SectionQualitaet />
        <SectionHandwerk />
        <SectionService />
        <SectionPartner />
        <SectionInnovation />
        <SectionSteps />
        <SectionUeberUns />
        {/*<PopupHint />*/}
      </div>
    </>
  );
};

export default Home;
